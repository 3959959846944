import React from 'react'
import Layout from 'layouts/en'
import styled from 'styled-components'
import design from 'design'
import { Title, Text, Row, Div, Box, Button } from 'components'
import { TryOrSchedule } from 'containers/en'

export default () => {
  return (
    <Layout p={5}>
      <Div pt={[6,8]} px={[0,6]} textAlign={['center', 'left']}>
        <Title mb={5} fontSize={[7,9]}>About Stairlin</Title>
        <Text tag={'h2'} mb={7} fontSize={[6,8]}>Stairlin’s mission is to provide self employed everything they need to start, run, and grow their businesses.</Text>
        <Text mb={5} fontSize={[3,5]} textAlign={'justify'}>Stairlin was founded in 2017 in Lausanne Switzerland by <strong>Thibault Dubuis</strong> and <strong>Bastien Gysler</strong> who were dumbfounded by the fact that it is easier to book a flight and a hotel on the other side of the planet than getting an appointment for a haircut in the neighbourhood.</Text>
        <Text mb={5} fontSize={[3,5]} textAlign={'justify'}>Every self-employed run into the same fundamental problems. Endless administrative work, late payments, no-shows, buried under email, and not enough time for what matters.</Text>
        <Text mb={5} fontSize={[3,5]} textAlign={'justify'}>To solve this issue, Stairlin develops a suite of simple tool, but very powerful when combined to reduces grunt work, increases their productivity, gives them insight about their business, and finally give them back time for their family or leisure.</Text>
        <Title mt={[8,7]} mb={[6,7]} fontSize={[7,8]}>Founders</Title>

        <Div display={['block', 'flex']} _justifyContent={'space-between'}>
          <Div flex={1} mr={[0,6]} _mb={[6,8]}>
            <Title mb={4} fontSize={[6,7]}>Bastien Gysler</Title>
            <Text mb={[5,6]} fontSize={6} color={'grayDark'}>CEO</Text>
            <Text fontSize={[3,5]} textAlign={'justify'}>Bastien is the co-founder of Stairlin and thanks to his innate taste for
              design, love for building products
              with cutting-edge technologies, security awareness and a dose of healthy paranoia Bastien is
              the right guy to spearhead our product development.
              <br />
              Bastien is a true believer that technology is meant to simplify our day-to-day life. As a backend
              engineer and agile project manager, he is building Stairlin because no-one is doing it right.
              <br />
              Previously to founding Stairlin, Bastien developed his skills as a top notch software
              engineer at several Swiss tech companies, Bastien has a Bachelor of Science - Business
              Information Technology from the HES SO.</Text>
          </Div>

          <Div flex={1} mt={[6,0]} _mb={[6,8]}>
            <Title mb={4} fontSize={[6,7]}>Thibault Dubuis</Title>
            <Text mb={[5,6]} fontSize={6} color={'grayDark'}>Chairman / Strategic partnerships</Text>
            <Text fontSize={[3,5]} textAlign={'justify'}>Thibault is the co-founder of Stairlin and with his many interests he has a knack for thinking about interesting
              and new ways to do things and solve problems. Thanks to his eclectic professional experience, fearlessness
              in learning new things and just the right dose of laziness to be efficient Thibault handle our customer
              development, business partnerships and investor relations.<br /> Previously to founding Stairlin, Thibault
              was a former HSBC banker and associate to the CFO at a swiss fintech company, Thibault has a B.A. from
              the University of St. Gallen and is a Chartered Alternative Investment Analyst.</Text>
          </Div>
        </Div>

        <Title mt={[8,7]} mb={[6,7]} fontSize={[7,8]}>Advisory Board</Title>
        <Div display={['block', 'flex']} _justifyContent={'space-between'}>
          <Div flex={1} mr={[0,6]} mb={[6,8]}>
            <Title mb={4} fontSize={[6,7]}>Patrick Botteron</Title>
            <Text fontSize={[3,5]} textAlign={'justify'}>Patrick Botteron is an experienced Angel Investor and Head of Private Banking from the Banque Cantonale Vaudoise. Patrick helps us with our business development thanks to his exhaustive angel investor experience and strong business acumen.</Text>
          </Div>

          <Div flex={1} mb={[8,8]}>
            <Title mb={4} fontSize={[6,7]}>Patrick Zbinden</Title>
            <Text fontSize={[3,5]} textAlign={'justify'}>Patrick Zbinden is the Co-Head of Asset Management for EFG International, a global private banking group listed on the Swiss stock exchange. Patrick helps us build up our business case and ensure we stay on solid financial grounds thanks to his vast investment banking experience.</Text>
          </Div>
        </Div>

        <TryOrSchedule />
      </Div>
    </Layout>
  )
}
